import dayjs from 'dayjs';
import { Button, Table, Tooltip } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { ColumnType } from 'antd/es/table';
import { FormattedMessage, useIntl } from 'react-intl';
import { dateStringSorter, formatDate, formatRequestDate } from '../../util/DateUtil.ts';
import { translate } from '../../translations/TranslationUtils.ts';
import { inventoryAbortController, useGetInventoryQuery } from '../../persistence/inventoryApiSlice.ts';
import { InventoryItem, InventoryItemCallOff, InventoryItemOrderBy } from '../../persistence/model/InventoryItem.ts';
import { formatPortalNumber } from '../../util/NumberUtil.ts';
import CallOffStatusTag from '../call-off/CallOffStatusTag.tsx';
import { ExclamationCircleOutlined, MinusOutlined, PlusOutlined, SendOutlined } from '@ant-design/icons';
import CallOffCartAddItemModal from './call-off-cart/CallOffCartAddItemModal.tsx';
import { CallOffCart, CallOffCartItem } from './call-off-cart/CallOffCartLogic.ts';
import ProductRenderer from '../product/ProductRenderer.tsx';
import './InventoryTable.css';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../persistence/store.ts';
import useCustomerContext from '../../persistence/useCustomerContext.ts';
import { setPage, setSort } from './InventoryTableSlice.ts';
import { toSorter } from '../../util/PortalUtil.ts';
import { AxSalesStatus } from '../../persistence/model/CallOff.ts';
import { useGetCustomerDeliveryDaysQuery } from '../../persistence/customerApiSlice.ts';

const ITEMS_PER_PAGE = 25;

const rowExpired = (item: InventoryItem): boolean => {
  if (!item.stockmonths) {
    return false;
  } else if (item.isStandardProduct) {
    return false;
  } else if (item.salesOrderStatus !== AxSalesStatus.BACKORDER) {
    return false;
  }

  return dayjs(item.deliveryDate).isBefore(dayjs().subtract(item.stockmonths, 'month'), 'day');
};

const ExpiredNotification = () => (
  <Tooltip title={<FormattedMessage id="inventory.table.expired" />}>
    <ExclamationCircleOutlined style={{ color: 'red' }} />
  </Tooltip>
);

type InventoryTableProps = {
  callOffCart: CallOffCart;
  setCallOffCart: React.Dispatch<React.SetStateAction<CallOffCart>>;
  loading?: boolean;
  readOnly?: boolean;
  allOrders?: boolean;
};

const InventoryTable = ({ callOffCart, setCallOffCart, loading, readOnly, allOrders }: InventoryTableProps): JSX.Element => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { customer } = useCustomerContext();
  const { page, productSearchCriteria, orderSearchCriteria, dateRange, inStockOnly, sort } = useSelector((state: RootState) => state.inventory);
  const [selectedItem, setSelectedItem] = useState<InventoryItem>(null);
  const { data: customerDeliveryDays } = useGetCustomerDeliveryDaysQuery(customer?.id, {skip: !customer});
  const { data: inventoryResponse, isFetching } = useGetInventoryQuery(
    {
      'customer-id': customer?.id,
      'from-date': formatRequestDate(dateRange[0]),
      'to-date': formatRequestDate(dateRange[1]),
      'include-settled': allOrders,
      _count: true,
    },
    { skip: !customer }
  );
  const initialSortedRows = useMemo(
    () => inventoryResponse?.data.slice().sort((a, b) => dateStringSorter(b.deliveryDate, a.deliveryDate)),
    [inventoryResponse]
  );
  const filteredRows = useMemo(() => {
    const psc = productSearchCriteria?.toLowerCase().trim();
    const osc = orderSearchCriteria?.toLowerCase().trim();

    return initialSortedRows
      ?.filter((item) => !inStockOnly || item.quantityInWarehouse > 0)
      .filter(
        (item) =>
          !psc ||
          item.productNumber?.toLowerCase().includes(psc) ||
          item.productName?.toLowerCase().includes(psc) ||
          item.externalItemId?.toLowerCase().includes(psc)
      )
      .filter((item) => !osc || item.salesOrderId?.toLowerCase().includes(osc) || item.purchOrderFormNum?.toLowerCase().includes(osc));
  }, [productSearchCriteria, orderSearchCriteria, initialSortedRows, inStockOnly]);

  useEffect(() => {
    return () => {
      inventoryAbortController.abort();
    };
  }, []);
  const columns = useMemo(
    () =>
      [
        {
          key: 'productName',
          dataIndex: 'productName',
          title: translate(intl, 'inventory.table.productName'),
          render: (_, record) => (
            <ProductRenderer productNumber={record.productNumber} productName={record.productName} externalItemId={record.externalItemId} />
          ),
        },
        {
          key: 'salesOrderId',
          dataIndex: 'salesOrderId',
          title: translate(intl, 'inventory.table.salesOrderId'),
          render: (_, record) => (
            <span style={{ display: 'inline-block' }}>
              <div>{record.salesOrderId}</div>
              {!!record.purchOrderFormNum && <div style={{ opacity: 0.6 }}>{record.purchOrderFormNum}</div>}
            </span>
          ),
        },
        {
          key: InventoryItemOrderBy.deliveryDate,
          dataIndex: 'deliveryDate',
          title: translate(intl, 'inventory.table.deliveryDate'),
          render: (value) => <span style={{ whiteSpace: 'nowrap' }}>{formatDate(intl, dayjs(value))}</span>,
          sorter: (a, b) => dateStringSorter(b.deliveryDate, a.deliveryDate),
          ...toSorter(sort, InventoryItemOrderBy.deliveryDate),
        },
        {
          key: InventoryItemOrderBy.orderedQuantity,
          dataIndex: 'orderedQuantity',
          title: translate(intl, 'inventory.table.orderedQuantity'),
          render: (value, record) => `${formatPortalNumber(value)} ${record.unit}`,
          sorter: (a, b) => a.orderedQuantity - b.orderedQuantity,
          ...toSorter(sort, InventoryItemOrderBy.orderedQuantity),
        },
        {
          key: InventoryItemOrderBy.deliveredQuantity,
          dataIndex: 'deliveredQuantity',
          title: translate(intl, 'inventory.table.deliveredQuantity'),
          render: (value, record) => `${formatPortalNumber(value)} ${record.unit}`,
          sorter: (a, b) => a.deliveredQuantity - b.deliveredQuantity,
          ...toSorter(sort, InventoryItemOrderBy.deliveredQuantity),
        },
        {
          key: InventoryItemOrderBy.quantityInWarehouse,
          dataIndex: 'quantityInWarehouse',
          title: translate(intl, 'inventory.table.quantityInWarehouse'),
          sorter: (a, b) => a.quantityInWarehouse - b.quantityInWarehouse,
          ...toSorter(sort, InventoryItemOrderBy.quantityInWarehouse),
          render: (value, record) => (
            <div>
              <div style={{ display: 'flex', justifyContent: 'space-between', whiteSpace: 'nowrap', gap: '5px' }}>
                <span>{`${formatPortalNumber(value)} ${record.unit}`}</span>
                {rowExpired(record) && <ExpiredNotification />}
              </div>
              {callOffCart.items[record.inventTransId] && (
                <div className="call-off-text">
                  <FormattedMessage id="inventory.table.inCallOff" />
                </div>
              )}
            </div>
          ),
        },
        ...(!allOrders
          ? [
              {
                key: '_actions',
                width: 45,
                render: (_, record) => <Button disabled={readOnly} icon={<SendOutlined />} onClick={() => setSelectedItem(record)} />,
              } as ColumnType<InventoryItem>,
            ]
          : []),
      ] as ColumnType<InventoryItem>[],
    [intl, sort, allOrders, callOffCart.items, readOnly]
  );

  const expandableColumns = useMemo(
    () =>
      [
        {
          key: '_spacer',
        },
        {
          key: 'createdAtDate',
          dataIndex: 'createdAtDate',
          title: translate(intl, 'inventory.table.expandable.createdAtDate'),
          render: (value) => formatDate(intl, dayjs(value)),
        },
        {
          key: 'orderedQuantity',
          dataIndex: 'orderedQuantity',
          title: translate(intl, 'inventory.table.expandable.orderedQuantity'),
        },
        {
          key: 'deliveryDate',
          dataIndex: 'deliveryDate',
          title: translate(intl, 'inventory.table.expandable.deliveryDate'),
          render: (value) => formatDate(intl, dayjs(value)),
        },
        {
          key: 'status',
          title: translate(intl, 'inventory.table.expandable.status'),
          dataIndex: 'status',
          render: (value) => <CallOffStatusTag status={value} />,
        },
      ] as ColumnType<InventoryItemCallOff>[],
    [intl]
  );

  const expandedRowRender = (record: InventoryItem) => (
    <Table dataSource={record.callOffRows.map((row, index) => ({ ...row, index }))} rowKey="index" columns={expandableColumns} pagination={false} />
  );

  return (
    <>
      <Table<InventoryItem>
        className="inventory-table top-align-table"
        columns={columns}
        size="small"
        dataSource={filteredRows}
        loading={loading || isFetching}
        rowKey="index"
        onChange={(_, filter, sorter) => {
          if (sorter !== sort) {
            dispatch(setSort(sorter as any));
          }
        }}
        pagination={{
          current: page,
          onChange: (pageNum) => dispatch(setPage(pageNum)),
          pageSize: ITEMS_PER_PAGE,
          position: ['bottomRight'],
          hideOnSinglePage: true,
          showSizeChanger: false,
          total: filteredRows?.length || 0,
        }}
        onRow={(data) => ({ className: (callOffCart.items[data.inventTransId] && 'row-in-call-off') || (rowExpired(data) && 'row-expired') })}
        expandable={{
          rowExpandable: (record) => record.callOffRows?.length > 0,
          expandedRowClassName: () => 'inventory-table-expanded-row',
          expandedRowRender,
          expandIcon: ({ expanded, onExpand, record }) => {
            if (!record.callOffRows?.length) {
              return null;
            }

            return (
              <span className="portal-table-expand-icon">
                {expanded ? <MinusOutlined onClick={(e) => onExpand(record, e)} /> : <PlusOutlined onClick={(e) => onExpand(record, e)} />}
              </span>
            );
          },
        }}
      />
      <CallOffCartAddItemModal
        inventoryItem={selectedItem}
        callOffCart={callOffCart}
        onCancel={() => setSelectedItem(null)}
        onSubmit={(newItem: CallOffCartItem) => {
          setCallOffCart((prevCart) => ({
            ...prevCart,
            items: {
              ...prevCart.items,
              [newItem.id]: newItem,
            },
          }));
          setSelectedItem(null);
        }}
        customerDeliveryDays={customerDeliveryDays?.data}
      />
    </>
  );
};

export default InventoryTable;
